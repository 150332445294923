/* eslint-disable react/display-name */
import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled from "styled-components";

const RichTextDisplay = ({ json }) => {
  console.log("RichTextDisplay -> json", json);

  const options = {
    renderNode: {
      "embedded-asset-block": (node) => (
        <div>
          {/* <h3>this is awesome image</h3> */}
          <img
            className="content-image"
            src={node.data.target.fields.file["en-US"].url}
            alt={""}
          />
          {/* <p>images provided by john doe</p> */}
        </div>
      ),
      "embedded-entry-block": (node) => {
        const { title, image, text } = node.data.target.fields;
        return (
          <div>
            <h1>Related article: {title["en-US"]}</h1>
            <img
              width="680"
              src={image["en-US"].fields.file["en-US"].url}
              alt={title}
            />
            {documentToReactComponents(text["en-US"])}
          </div>
        );
      },
    },
  };

  return (
    <RichTextWrapper>
      {documentToReactComponents(json, options)}
    </RichTextWrapper>
  );
};

const RichTextWrapper = styled.div`
  .content-image {
    position: relative;
    margin: 0 -50% 0 -50%;
    padding: 6em 0 1rem 0;
    max-width: 900px;
    min-width: 100%;
  }
  @media (max-width: 900px) {
    .content-image {
      max-width: 100%;
    }
  }
`;

export default RichTextDisplay;
